* {
	font-size: 12pt;
}

:root {
	box-sizing: border-box;
}

.App {
	text-align: center;
	display: grid;
	grid-template-areas: "header" "content" "footer";
	height: 80vh;
}

.App-header {
	background-color: #ffffff;
	justify-content: center;
	grid-area: header;
}

.App-market {
	height: 100px;
	background-color: white;
}

.App-content {
	grid-area: content;
}

.App-footer {
	grid-area: footer;
}

.App-link {
	color: #61dafb;
}

.App_title {
	position: sticky;
	position: -webkit-sticky;
	top: 30px;
	background: linear-gradient(
		to right,
		rgb(183, 252, 200),
		rgb(255, 255, 255) 75%
	);
	height: 30px;
	text-align: left;
	/* display: block; */
}

.App_title h2 {
	font-size: large;
	color: #64aa6d;
	margin-left: 10px;
	font-weight: 800;
	display: inline-block;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0%;
	font-family: "Do Hyeon", sans-serif;
	line-height: 30px;
}

.App-farm-outside {
	background-size: cover;
	background-color: #64aa6d;
	background-blend-mode: screen;
	height: 300px;
	width: 100%;
}

.App-farm-outside {
	background-image: url("./farm_outside.png");
}

.App-farm-outside.hide {
	background-image: none;
}

.App-container {
	margin-left: 0;
	margin-right: 0;
	padding-left: 0;
	padding-right: 0;
	max-width: 100%;
}

.App-content * {
	transition: 0.5s transform ease, 0.7s opacity ease 0.2s;
}

.hide img {
	visibility: hidden;
	opacity: 0;
	transform: translateX(100%);
}
