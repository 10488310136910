.Header_container {
	margin-top: 0;
	margin-bottom: 0;
	z-index: 100;
	display: flex;
}

.Header_image_container {
	display: flex;
	width: 100%;
	background-color: #f8f7fd;
	position: relative;
	min-height: 200px;
}

.Header_title {
	position: absolute;
	display: flex;
	flex-direction: column;
	place-content: center;
	justify-content: center;
	align-items: flex-start;
	height: 100%;
	margin-top: auto;
	margin-bottom: auto;
}

.Header_title * {
	font-family: "Nanum Gothic";
	font-weight: 800;
	margin: 0;
	padding: 0;
	text-shadow: 0 0 0.5rem white;
	margin-left: 2rem;
}

.Header_title1 {
	font-size: 1.4rem;
	font-weight: 800;
	color: #a2c25d;
}
.Header_title2 {
	font-size: 2rem;
	font-weight: 800;
	color: #7da918;
}
.Header_description {
	font-size: 0.8rem;
	font-weight: 800;
	color: #a2c25d;
	margin-top: 1rem;
}
.Header_image_container > picture {
	margin-left: auto;
}

.Header_image {
	height: 100%;
	max-height: 200px;
}

@media (max-width: 411px) {
	.Header_image_container > picture {
		display: none;
	}
}

.Header_sticky {
	position: fixed;
	display: flex;
	background-color: rgba(255, 255, 255, 0.7);
	font-size: large;
	font-weight: 900;
	color: #7aa613;
	width: 100%;
	height: 30px;
	opacity: 0;
	justify-content: center;
	align-items: center;
}
