.HowToEat_introImg {
    object-fit:contain;
    max-width: fit-content;
    margin-left:auto;
    margin-right: auto;
    margin-bottom: 1.5em;
}


.HowToEat_list {
    counter-reset: howto-ripe;
}

.HowToEat_list ul {
    padding-left: 0;
}

.HowToEat_list li {
    list-style: none;
    font-size: 0.9rem;
    display: grid;
    width: 100%;
    grid-template-columns: 50px 1fr;
    font-family: 'Do Hyeon', sans-serif;
}

.HowToEat_index {
    font-size: 3em;
    grid-column: 1;
    text-align: left;
    color: #accc82;
    counter-increment: howto-ripe;
    font-family: 'Do Hyeon', sans-serif;
    line-height: 50px;
}

.HowToEat_howto {
    color:#6b9139;
    text-align: left;
    grid-column: 2;
}

.HowToEat_howto_desc {
    text-align: left;
    font-size: 0.9rem;
    margin-top: -1rem;
    grid-column: 2;
    font-family: 'Nanum Myeongjo', serif;
}

.HowToEat_howto_desc::before {
    content: '(';
}

.HowToEat_howto_desc::after {
    content: ')';
}