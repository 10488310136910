.Market_img {
	object-fit: contain;
	width: 100%;
	/* filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.7)); */
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.7);
}

.Market_img_container {
	text-align: center;
}

.Market_link_container {
	text-align: left;
	margin-top: auto;
	margin-bottom: auto;
}
.Market_link_container a * {
	white-space: nowrap;
	break-inside: avoid;
	word-break: keep-all;
}

.Market_mall_logo_container {
	width: 120px;
	position: relative;
}

.Market_mall_logo_container > span {
	position: absolute;
	z-index: -1;
	width: 100%;
	left: 0;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.8rem;
	margin-top: auto;
	margin-bottom: auto;
}

.Market_mall_logo_container > img {
	background-color: white;
}

.Market_mall_logo {
	object-fit: contain;
	width: 100px;
	padding: 5px;
	border: solid 1px #cadd9c;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.7);
	margin: 10px;
	display: inline-flex;
}

.Market_icon_top {
	height: 70%;
}

.Market_shoppingcart {
}

.Market_container {
}
