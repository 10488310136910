.NavDrawer_button {
	width: 30px;
	height: 30px;
}

.NavDrawer_button:focus {
	outline: none;
	border: none;
}

.NavDrawer_container {
	position: fixed;
	z-index: 1000;
}
