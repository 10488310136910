.Nutrition_introImg {
    object-fit:contain;
    max-width: fit-content;
    margin-left:auto;
    margin-right: auto;
    margin-bottom: 1.5em;
}

.Nutrition_box {
    outline: solid 1px #b4d89c;
    outline-offset: -0.5em;
    padding: 2em;
}

.Nutrition_box1 {
    background-image: url("./tip_tag.png");
    background-repeat:no-repeat;
    background-position: 0.5em 0.5em;
}

.Nutrition_box2 {
    background-image: url("./health_info_tag.png");
    background-repeat: no-repeat;
    background-position: 0.5em 0.5em;
}
.Nutrition_list {
    counter-reset: nutrition_order;
}

.Nutrition_text {
    text-align: left;
}

.Nutrition_text2 * {
    font-family: 'Nanum Myeongjo', serif;
    font-size: 0.9rem;
}

.Nutrition_text2 strong {
    color: #59ca0d;
}

.Nutrition_text3 * {
    font-family: 'Do Hyeon', sans-serif;
    font-size: 0.9rem;
}

.container.Nutrition_text3 {
    padding-left: 0;
    padding-right: 0;
}


.Nutrition_list ul {
    margin-bottom: 0;
    padding-left: 0;
}

.Nutrition_list li {
    list-style: none;
    text-align: left;
}

.Nutrition_list li:not(.Nutrition_list_first) {
    color: #8d8d8d;
}

.Nutrition_list_first {
    color:#59ca0d;
    font-weight: bolder;
    font-size: 1rem;
    margin-top: -2pt;
}

.Nutrition_list_first::after {
    content: ''
}

.Nutrition_crown {
    width: 1.5em;
    height: 1.5em;
}

.Nutrition_title span{
    display: inline-flex;
    font-size: large;
    font-family: 'Do Hyeon', sans-serif;
}

.Nutrition_title span:not(first-child){
    margin-left: 0.5em;
    word-wrap: none;
    word-break: keep-all;
}

.Nutrition_health_info_list_side_effects {
    margin-left: -2em;
}

.Nutrition_health_info_list_side_effects > li {
    font-family: 'Nanum Myeongjo', serif;
    font-size: 0.9rem;
}

.Nutrition_list li::before {
    counter-increment: nutrition_order;
    font-family: 'Do Hyeon', sans-serif;
    content: counter(nutrition_order) '위 ';
    font-size: small;
}


.Nutrition_health_info_list {
    counter-reset: why-should_eat;
}

.Nutrition_text.Nutrition_text4 > ul {
    padding-left: 0;
}

ul.Nutrition_health_info_list_side_effects{
    padding-left: 1em;
}

.Nutrition_health_info_list > li {
    list-style: none;
    font-size: 0.9rem;
    display: grid;
    width: 100%;
    grid-template-columns: 50px 1fr;
}

.Nutrition_health_info_list_index {
    font-size: 3em;
    grid-column: 1;
    text-align: left;
    color: #accc82;
    counter-increment: why-should_eat;
    font-family: 'Do Hyeon', sans-serif;
    line-height: 50px;
}

.Nutrition_health_info_list_howto {
    color:#6b9139;
    text-align: left;
    grid-column: 2;
    font-family: 'Do Hyeon', sans-serif;
}

.Nutrition_health_info_list_howto_desc {
    text-align: left;
    font-size: 0.9rem;
    margin-top: -1rem;
    grid-column: 2;
    font-family: 'Nanum Myeongjo', serif;
}

.Nutrition_health_info_list_howto_desc::before {
    content: '(';
}

.Nutrition_health_info_list_howto_desc::after {
    content: ')';
}